import { MenuIcon } from "assets/icons";
import classNames from "classnames";
import Link from "next/link";
import { Fragment, useState } from "react";
import { access } from "utility/accessor";

import { IconButton } from "../buttons";
import { ResponsiveMenuProps } from "./interfaces";
import { ResponsiveText } from "components/games/atomic/responsive-text/responsive-text";
import { useScreenSize } from "utility/detection";

export function ResponsiveMenu<Item>({
  items,
  link: linkAccessor,
  content: contentAccessor,
  linkClassName = "text-charcoal-600 font-poppins",
  hSeperator = <div className="h-[2.125rem] w-[1px] bg-secondary-200"></div>,
  breakpoint = "lg",
  supplement,
  ...props
}: ResponsiveMenuProps<Item>) {
  const [menuOpen, setMenuOpen] = useState(false);
  const { isDesktop } = useScreenSize();
  return (
    <nav {...props}>
      <IconButton
        className={classNames(
          { md: "lg:hidden", lg: "lg:hidden", xl: "lg:hidden" }[breakpoint],
        )}
        onClick={() => setMenuOpen(!menuOpen)}
        icon={MenuIcon}
      />
      <div
        className={classNames(
          menuOpen || "hidden lg:block",
          "fixed left-0 top-0",
          "h-screen w-screen",
          "z-20 bg-overlay-light",
          "animate-fade-in",
          ...{
            md: [
              "md:static md:left-auto md:top-auto",
              "md:h-fit md:w-fit",
              "md:bg-transparent",
              "md:animate-none",
            ],
            lg: [
              "lg:relative lg:left-auto lg:top-auto",
              "lg:h-fit lg:w-fit",
              "lg:bg-transparent",
              "lg:animate-none",
            ],
            xl: [
              "xl:relative xl:left-auto xl:top-auto",
              "xl:h-fit xl:w-fit",
              "xl:bg-transparent",
              "xl:animate-none",
            ],
          }[breakpoint],
        )}
        onClick={() => setMenuOpen(false)}
      >
        <div className="relative h-full w-full ">
          <div
            className={classNames(
              "z-20 bg-white",
              "h-screen",
              "fixed right-0",
              menuOpen ? "animate-slide-in" : "animate-slide-out",
              menuOpen ? "flex flex-col" : "hidden transition",
              "gap-4 py-8 lg:py-0",
              ...{
                md: [
                  "md:bg-transparent",
                  "md:h-fit md:w-fit",
                  "md:static md:right-auto",
                  "md:animate-none",
                  "md:flex md:gap-4",
                  "md:items-center md:justify-end md:text-sm",
                ],
                lg: [
                  "lg:bg-transparent",
                  "lg:h-fit lg:w-fit",
                  "lg:static lg:right-auto",
                  "lg:animate-none",
                  "lg:flex lg:gap-4",
                  "lg:items-center lg:justify-end lg:text-sm",
                ],
                xl: [
                  "xl:bg-transparent",
                  "xl:h-fit xl:w-fit",
                  "xl:static xl:right-auto",
                  "xl:animate-none",
                  "xl:flex xl:gap-4",
                  "xl:items-center xl:justify-end xl:text-sm",
                ],
              }[breakpoint],
            )}
          >
            {items.map((item, index) => {
              const link = access(linkAccessor, item);
              return (
                <Fragment key={link}>
                  <Link href={link} className={linkClassName}>
                    <ResponsiveText className="lg:text-[13px] tb:text-base">
                      {access(contentAccessor, item)}
                    </ResponsiveText>
                  </Link>
                  {index + 1 < items.length && (
                    <div
                      className={classNames(
                        "hidden",
                        { md: "md:block", lg: "lg:block", xl: "xl:block" }[
                          breakpoint
                        ],
                      )}
                    >
                      {hSeperator}
                    </div>
                  )}
                </Fragment>
              );
            })}
            <div
              className={classNames(
                "flex-1",
                "flex flex-col justify-end",
                {
                  md: "lg:flex-initial",
                  lg: "lg:flex-initial",
                  xl: "xl:flex-initial",
                }[breakpoint],
              )}
            >
              {supplement}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
