import { LogoutIcon, SettingsIcon } from "assets/icons";
import { BiCreditCard, BiDollar, BiSolidUserCircle } from "react-icons/bi";
import { HiOutlineSparkles, HiSparkles, HiUserGroup } from "react-icons/hi2";
import { Badge, TextButton } from "components/atomic";
import { Avatar } from "components/atomic/avatar/avatar";
import { ResponsiveMenu } from "components/atomic/responsive-menu";
import { EmployerNotificationIcon } from "components/notifications";
import { Popup } from "components/popup";
import { useUserStatus } from "hooks/auth";
import { useTranslation } from "hooks/translation";
import Link from "next/link";
import {
  EmployerRoutes,
  GetEmployerRoleUrl,
  PublicRoutes,
} from "routes/routes";
import { logout } from "store/auth";
import { useEmailThreads } from "store/employer/employer-inbox-manager";
import { EmailThreadItem } from "store/job-applicant/job-applicant-models";
import { PageData } from "store/pagination";
import { UserType, useUser } from "store/user";
import { emptyArray } from "utility/empty";
import { titleCase } from "utility/format-text/title-case";
import { TbReportMoney } from "react-icons/tb";
import { BiLock } from "react-icons/bi";
import { useCurrentSubscriptionPlan } from "store/subscription";
import { FaUserCheck } from "react-icons/fa";
import { ResponsiveText } from "components/games/atomic/responsive-text/responsive-text";

export function EmployerMenu() {
  const { $t } = useTranslation();
  const { currentSubscriptionPlan, fetchedSubscriptionPlan } =
    useCurrentSubscriptionPlan();
  const { emailThreads = emptyArray as any } = useEmailThreads({
    is_read: false,
    enabled: fetchedSubscriptionPlan && currentSubscriptionPlan?.hasEmailAccess,
  });

  const { data: user } = useUser();

  const { companyProfileIsCompleted, IsQuestionnaireComplete } = useUserStatus([
    UserType.Employer,
    UserType.Admin,
  ]);
  const menuItems = [
    { title: "jobs", link: EmployerRoutes.JobList },
    {
      title: "applicants",
      link: GetEmployerRoleUrl.forApplicantList,
    },
    { title: "assessments", link: EmployerRoutes.AssessmentList },
    {
      title: (
        <ResponsiveText className="relative flex">
          Interviews
          <ResponsiveText
            fontSize="sm"
            className="  flex -translate-x-1.5  -translate-y-3.5 scale-75 gap-0.5 rounded-lg bg-gradient-to-r from-primary to-[#0088FF] p-0.5  px-1 font-poppins text-white"
          >
            <HiSparkles className=" h-4 w-4 rotate-180 pb-0.5" />
            AI
          </ResponsiveText>
        </ResponsiveText>
      ),

      link: EmployerRoutes.Interviews,
    },
    {
      title: (
        <div className="flex">
          Emails
          {emailThreads?.pagination?.count > 0 && (
            <Badge
              className="ml-2"
              variant="warning"
              paddingClassName="py-0.5 px-1.5"
            >
              {emailThreads?.pagination?.count}
            </Badge>
          )}
          {!currentSubscriptionPlan?.hasEmailAccess && (
            <BiLock className="ml-1 h-6 w-6 text-warning " />
          )}
        </div>
      ),

      link: EmployerRoutes.EmailList,
    },
    {
      title: <div className="flex">Talent Marketplace</div>,
      link: EmployerRoutes.MarketplaceApplicantList,
    },
    {
      title: <div className="lg:hidden">Settings</div>,
      link: EmployerRoutes.Settings,
    },
    {
      title: (
        <TextButton
          className="py-0 lg:hidden"
          leftIcon={LogoutIcon}
          onClick={logout}
          scale="small"
        >
          {$t("common:logout")}
        </TextButton>
      ),

      link: PublicRoutes.Login,
    },
  ];

  return (
    <nav
      className="flex h-full w-full flex-row-reverse items-center gap-7 overflow-hidden lg:flex-row"
      data-testid="employer-menu"
    >
      <section className="flex items-center justify-end space-x-7  font-poppins text-primary lg:flex-grow lg:justify-start">
        {IsQuestionnaireComplete && companyProfileIsCompleted ? (
          <ResponsiveMenu
            items={menuItems}
            link={(item) =>
              typeof item.link === "function" ? item.link(user) : item.link
            }
            linkClassName="poppins-regular-primary text-lg"
            content={(item) =>
              typeof item.title === "string"
                ? titleCase(item.title)
                : item.title
            }
            hSeperator=""
          />
        ) : null}
      </section>
      <EmployerNotificationIcon />

      <section className="hidden lg:block">
        <Popup
          showOnClick
          content={
            <nav className="flex min-w-[180px] flex-col items-end bg-white px-6 py-4 drop-shadow">
              <Link href={EmployerRoutes.SettingsProfile} className="w-full">
                <TextButton
                  className="w-full"
                  align="start"
                  leftIcon={BiSolidUserCircle}
                >
                  {$t("account:employer-settings.title.profile")}
                </TextButton>
              </Link>
              <Link href={EmployerRoutes.SettingsWorkspace} className="w-full">
                <TextButton
                  className="w-full"
                  align="start"
                  leftIcon={SettingsIcon}
                >
                  {$t("account:employer-settings.title.workspace")}
                </TextButton>
              </Link>
              <Link href={EmployerRoutes.SettingsTeam} className="w-full">
                <TextButton
                  className="w-full"
                  align="start"
                  leftIcon={HiUserGroup}
                >
                  {$t("account:employer-settings.title.team")}
                </TextButton>
              </Link>
              <Link href={EmployerRoutes.Plans} className="w-full">
                <TextButton
                  className="w-full"
                  align="start"
                  leftIcon={TbReportMoney}
                >
                  <span className="pt-10">
                    {$t("account:employer-settings.title.plans")}
                  </span>
                </TextButton>
              </Link>
              <Link href={EmployerRoutes.Payment} className="w-full">
                <TextButton
                  className="w-full"
                  align="start"
                  leftIcon={BiCreditCard}
                >
                  {$t("account:employer-settings.title.payment")}
                </TextButton>
              </Link>
              <Link
                href={EmployerRoutes.ReferenceCheckForms}
                className="w-full"
              >
                <TextButton
                  className="w-full"
                  align="start"
                  leftIcon={FaUserCheck}
                >
                  {$t("account:employer-settings.title.reference-checks")}
                </TextButton>
              </Link>

              <TextButton
                className="w-full"
                align="start"
                leftIcon={LogoutIcon}
                onClick={logout}
              >
                {$t("common:logout")}
              </TextButton>
            </nav>
          }
          options={{ placement: "bottom-end", offset: [-5, 5] }}
        >
          <Avatar>
            {(user.firstName || user.lastName
              ? `${user.firstName?.[0] ?? ""}${user.lastName?.[0] ?? ""}`
              : user.email?.[0]
            ).toUpperCase()}
          </Avatar>
        </Popup>
      </section>
    </nav>
  );
}
