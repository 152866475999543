import { Id } from "store/ph-api";
import { QuestionSetId } from "store/question-set";
import { TestId } from "store/test/interfaces";

import { Option } from "./responses";
import { JobCategoryId } from "store/job-category/interfaces";
import { EmployerId } from "store/employer";

export type QuestionId = Id & { __type: "QuestionId" };
export type FollowUpId = Id & { __type: "FollowUpId" };
export type OptionId = Id & { __type: "OptionId" };
export type Answer = string | OptionId;
export type QuestionOptionId = Id & { __type: "QuestionOptionId" };

export enum QuestionType {
  MULTIPLE_CHOICE = "multiple-choice",
  VIDEO = "video",
  MULTIPLE_ANSWER = "multiple-answer",
  WEIGHTED = "weighted",
  MULTI_INPUT = "multi-input",
  FREE_TEXT = "free-text",
  NUMERIC = "numeric",
}

export const validRoughQuestionTypes = [
  "Multiple Choice",
  "Free Text",
] as const;

export type RoughQuestionType = (typeof validRoughQuestionTypes)[number];

export const questionsTypesWithOptions = [
  QuestionType.MULTIPLE_CHOICE,
  QuestionType.MULTIPLE_ANSWER,
  QuestionType.WEIGHTED,
];

export type QuestionContext =
  | "job"
  | "test"
  | "company"
  | "reference-check"
  | "interview";

export interface QuestionOption {
  optionId: OptionId;
  questionOptionId: QuestionOptionId;
  text: string;
  weight: number;
  answerCount: number;
  isCorrect: boolean;
}

export interface BasicQuestion<Context extends QuestionContext> {
  context: Context;
  __context: "Question";
  questionId: QuestionId;
  type: QuestionType;
  title: string;
  followUpCount: number;
  jobCategory?: JobCategoryId;
}

export interface QuestionDetails<Context extends QuestionContext>
  extends BasicQuestion<Context> {
  text: string;
  options: QuestionOption[];
  timeLimit: number;
  followUp?: FollowUpQuestion[];
  usedTests: {
    testId: TestId;
    questionSets: {
      questionSetId: QuestionSetId;
      order: number;
    }[];
    name: string;
  }[];
  relevance: string;
  lookFor: string;
}

export interface QuestionListTest {
  id: TestId;
  name: string;
  questionSetIds: QuestionSetId[];
}

export interface QuestionListItem<Context extends QuestionContext>
  extends BasicQuestion<Context> {
  tests: QuestionListTest[];
  text?: string;
  relevance?: string;
  lookFor?: string;
  disabled?: FollowUpId[];
  employerId?: EmployerId;
  archived?: boolean;
  stock?: boolean;
}

export interface FollowUpQuestionListItem {
  __context: "FollowUpQuestion";
  id: FollowUpId;
  questionId: QuestionId;
  parentId: QuestionId;
  option: OptionId | QuestionOptionId;
  isSelected: boolean;
  type: QuestionType;
  title: string;
  timeLimit: number;
  text?: string;
}

export interface FollowUpQuestion extends FollowUpQuestionListItem {
  options?: QuestionOption[] | Option[];
}

export interface QuestionFollowUps {
  __context: "Question";
  questionId: QuestionId;
  followups: FollowUpQuestionListItem[];
}
