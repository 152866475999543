import { NumberInput } from "components/atomic/input/interfaces";
import { UserId } from "store/user";
import { AssessmentId } from "store/assessment/interfaces";
import { JobCategoryId } from "store/job-category/interfaces";
import { Id } from "store/ph-api";
import { QuestionSetId } from "store/question-set";
import {
  FollowUpId,
  FollowUpQuestion,
  OptionId,
  QuestionFollowUps,
  QuestionId,
  QuestionListItem,
  QuestionOption,
  QuestionType,
} from "store/question/interfaces";

export type SharedLinkId = Id & { __type: "SharedLinkId" };
export type SharedLinkItemId = Id & { __type: "SharedLinkItemId" };
export type ApplicantSharedLinkId = Id & { __type: "ApplicantSharedLinkId" };
export type SharedAssessmentId = Id & { __type: "SharedAssessmentId" };
export type TestId = Id & { __type: "TestId" };
export type GameId = Id & { __type: "GameId" };
export type TestQuestionId = Id & { __type: "TestQuestionId" };
export type TestQuestionSetId = Id & { __type: "TestQuestionSetId" };

export enum TestCategory {
  Cognitive = "cognitive",
  Personality = "personality",
  Experience = "experience",
  Skill = "skill",
  Game = "game",
  Interview = "interview",
}

export enum TestType {
  Admin = "admin",
  Employer = "employer",
}

export enum TestDifficulty {
  NA = "not-applicable",
  Easy = "easy",
  Medium = "medium",
  Hard = "hard",
}

export type Minutes = NumberInput;

export type TestAssessment = {
  id: AssessmentId;
  name: string;
  description: string;
};

export type TestJobCategory = {
  id: JobCategoryId;
  name: string;
};

export interface TestSummary {
  id: TestId;
  name: string;
  duration?: Minutes;
  difficulty: TestDifficulty;
}

export interface TestListItem extends TestSummary {
  timeLimit?: Minutes;
  category: TestCategory;
  questionCount: number;
  description?: string;
  game?: GameId;
  gameDenominator?: number;
}
export interface Test extends TestListItem {
  directions?: string;
  timeLimit?: Minutes;
  questionSets: TestQuestionSet[];
  assessments: TestAssessment[];
  jobCategories: TestJobCategory[];
  gameUrl?: string;
}
export interface PreviewTest extends Omit<Test, "questionSets"> {
  questions: TestQuestionWithOptions[];
}

export interface TestStatistics extends Test {
  avg: number;
  max: number;
  median: number;
  min: number;
}

export interface QuestionSet {
  id?: TestQuestionSetId;
  testId?: TestId;
  questionSetId?: QuestionSetId;
  order: number;
  questions: AnyTestQuestion[];
  benchmarkTimePerQuestion: NumberInput;
}

export interface TestQuestionSet {
  id: TestQuestionSetId;
  testId: TestId;
  questionSetId: QuestionSetId;
  order: number;
  name: string;
  benchmarkTimePerQuestion: NumberInput;
  questions: TestQuestion[];
}

export interface TestQuestion {
  context: "test";
  __context: "TestQuestion";
  id: TestQuestionId;
  testId: TestId;
  questionId: QuestionId;
  order: number;
  type: QuestionType;
  title: string;
  followUpCount: number;
  text?: string;
  options?: QuestionOption[];
  disabled?: FollowUpId[];
  timeLimit: NumberInput;
}
export interface TestQuestionWithOptions extends TestQuestion {
  options: QuestionOption[];
  timeLimit: number;
}

export type AnyTestQuestion = QuestionListItem<"test"> | TestQuestion;

export interface TestFollowUpQuestion {
  context: "test";
  __context: "TestFollowUpQuestion";
  id: FollowUpId;
  questionId: QuestionId;
  parentId: TestQuestionId;
  option: OptionId;
  type: QuestionType;
  title: string;
  isSelected: boolean;
  followUpCount: number;
  isDisabled: boolean;
}

export type AnyTestFollowUpQuestion = FollowUpQuestion | TestFollowUpQuestion;

export interface TestQuestionFollowUps {
  context: "test";
  __context: "TestQuestion";
  testQuestionId: TestQuestionId;
  questionId: QuestionId;
  followups: AnyTestFollowUpQuestion[];
  disabled: FollowUpId[];
}

export type AnyTestQuestionFollowUps =
  | QuestionFollowUps
  | TestQuestionFollowUps;

export interface ApplicantSharedLink {
  applicantSharedLinkId: ApplicantSharedLinkId;
  createdAt: Date;
  updatedAt: Date;
  owner: UserId;
  link: SharedLinkId;
}
export interface RetrieveApplicantSharedLink {
  applicantSharedLinkId: ApplicantSharedLinkId;
  createdAt: Date;
  updatedAt: Date;
  owner: UserId;
  link: SharedLinkId;
}

export interface SharedLinkItem {
  test?: TestId;
  assessment?: AssessmentId;
}
